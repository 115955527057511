<script setup lang="ts">
  const props = defineProps<{
    name?: string;
  }>();

  // Auto-load icons
  const icons = Object.fromEntries(
    Object.entries(import.meta.glob('~/assets/icons/*.svg', { as: 'raw' })).map(
      ([key, value]) => {
        const filename = key.split('/').pop()!.split('.').shift();
        return [filename, value];
      },
    ),
  );

  // Lazily load the icon
  const icon = props.name && (await icons?.[props.name]?.());
</script>

<!-- eslint-disable vue/no-v-html -->
<template>
  <span
    v-if="icon"
    class="VIcon flex min-h-em min-w-em items-center justify-center"
    v-html="icon"
  />
</template>
